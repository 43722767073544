<template>
  <div class="addActivity">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :exportParams="exportParams"
    >
      <template #headBtnSlot>
        <v-button
          text="新增"
          @click="create"
          v-if="activityCategory !== 20"
        ></v-button>
        <v-button text="返回" @click="previous"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="活动名称" v-model="activityName" disabled />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="取消"
          type="text"
          v-if="scope.row.joinUserStatus === 0"
          @click="toCancel(scope.row)"
        />
        <v-button
          v-if="scope.row.signInNum > 0"
          text="编辑积分"
          type="text"
          @click="toPoint(scope.row)"
        />
      </template>
    </v-list>
    <v-dialog
      class="create-dialog"
      title="新增"
      v-model="createvisible"
      width="50%"
      @confirm="check"
    >
      <el-form>
        <el-form-item label="搜索可参与人：">
          <div class="wrap">
            <v-input
              placeholder="请输入手机号"
              v-model="mobileNum"
              suffix-icon="el-icon-search"
            />
            <v-button class="btn" @click="getUserDetail" text="搜索" />
          </div>
        </el-form-item>
      </el-form>
      <div class="info-card" v-if="createInfo">
        <p><span>姓名：</span>{{ createInfo.userName || "--" }}</p>
        <p>
          <span>性别：</span
          >{{ createInfo.sex ? sexMap[createInfo.sex] : "--" }}
        </p>
        <p><span>电话号码：</span>{{ createInfo.mobileNum || "--" }}</p>
      </div>
    </v-dialog>
    <v-dialog
      title="取消改报名人的所有参与人"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="doCancel"
    >
      <p style="text-align: center; line-height: 40px">
        是否确认取消该参与人？
      </p>
    </v-dialog>
  </div>
</template>

<script>
import {
  cancelActivityApply,
  activityApplicantsList,
  getUserDetailURL,
  getActivityApplyForUserURL,
  exportApplicantsUrl,
} from "./api.js";
import { createImgVNode } from "@/utils/utils.js";
import { relationList } from "./map";
export default {
  name: "applyActivity",
  data() {
    return {
      activityCategory: undefined, // 活动类型 20-时间银行 19-幸福学堂 1-热门活动
      exportParams: {
        url: exportApplicantsUrl,
        methods: "post",
        params: {
          activityId: this.$route.query.id,
          joinUserStatus: 0,
        },
      },
      scheduleId: "",
      activityName: this.$route.query.name,
      cancelStatusList: [
        { value: "0", label: "正常报名" },
        { value: "1", label: "报名人取消" },
        { value: "2", label: "发起人取消" },
      ],
      searchParam: { activityId: this.$route.query.id, joinUserStatus: "0" },
      tableUrl: activityApplicantsList,
      headers: [
        {
          prop: "userName",
          label: "报名人",
        },
        {
          prop: "housePath",
          label: "报名人房号",
        },
        {
          prop: "joinUserStatus",
          label: "报名状态",
          formatter: (row, prop) => {
            //状态：0 正常，1 报名人取消，2 发起人取消
            if (row.joinUserStatus === 0) {
              return "正常";
            } else if (row.joinUserStatus === 1) {
              return "报名人取消";
            } else if (row.joinUserStatus === 2) {
              return "发起人取消";
            }
            return "";
          },
        },
        {
          prop: "applicantsName",
          label: "参与人",
          align: "center",
        },
        {
          show: this.$route.query.type == 20,
          prop: "memberCode",
          label: "身份证号码",
        },
        {
          // show: this.$route.query.type != 20,
          prop: "applicantsPhone",
          label: "参与人手机号",
          align: "center",
        },
        {
          prop: "applicantsSex",
          label: "性别",
          formatter: (row, prop) => {
            if (row.applicantsSex === 1) {
              return "男";
            } else {
              return "女";
            }
          },
        },
        {
          prop: "score",
          label: "分数",
        },
        {
          prop: "appliedTime",
          label: "报名时间",
        },
        {
          prop: "remark",
          label: "报名备注信息",
        },
        {
          show: this.$route.query.type == 20,
          prop: "signInTime",
          label: "签到时间",
        },
        {
          show: this.$route.query.type == 20,
          prop: "signOffTime",
          label: "签出时间",
        },
        {
          show: this.$route.query.type == 20,
          prop: "earnPoint",
          label: "获得积分数目",
        },
        {
          prop: "scheduleStartDate",
          label: "场次开始时间",
        },
        {
          prop: "scheduleEndDate",
          label: "场次结束时间",
        },
      ],
      dialogFormVisible: false,
      tempObj: {
        applicantsId: "",
        userId: null,
        userName: null,
        cancelReason: "",
      },
      createvisible: false,
      mobileNum: "",
      createInfo: null,
      sexMap: {
        "-1": "未知",
        1: "男",
        2: "女",
      },
    };
  },
  created() {
    this.activityCategory = Number(this.$route.query.type);
    this.scheduleId = this.$route.query.scheduleId;
    this.$setBreadList("参与人列表");
  },
  methods: {
    getUserDetail() {
      if (!this.mobileNum) {
        this.$message.info("请输入手机号");
        return;
      }
      this.$axios
        .post(getUserDetailURL, {
          mobile: this.mobileNum,
          tenantId: this.$store.state.app.userInfo.tenantId,
          userSource: 1,
        })
        .then((res) => {
          if (res.code && res.data) {
            this.createInfo = res.data;
            return;
          }
          this.$message.error(res.msg || "服务器错误");
        });
    },
    create() {
      this.mobileNum = "";
      this.createInfo = null;
      this.createvisible = true;
    },
    check() {
      if (!this.createInfo.mobileNum) {
        this.$message.info("请选择用户");
        return;
      }
      this.$axios
        .post(getActivityApplyForUserURL, null, {
          params: {
            activityId: this.searchParam.activityId,
            scheduleId: this.scheduleId,
            // communityId: "",
            mobileNum: this.createInfo.mobileNum,
            // remarkData: "",
          },
        })
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.createvisible = false;
            this.$refs.list.search();
            this.$message.success("操作成功");
            return;
          }
          this.$message.error(res.msg);
        });
    },
    previous() {
      this.$router.go(-1);
    },

    toCancel(data) {
      this.dialogFormVisible = true;
      this.tempObj.userId = data.userId;
      this.tempObj.userName = data.userName;
      this.tempObj.applicantsId = data.applicantsId;
    },
    doCancel() {
      this.$axios
        .post(`${cancelActivityApply}`, null, {
          params: {
            activityId: this.searchParam.activityId,
            applicantsId: this.tempObj.applicantsId, // 参与人id
            userId: this.tempObj.userId,
            // cancelReason: this.tempObj.cancelReason,
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.dialogFormVisible = false;
            this.$message.success("保存成功");

            this.$refs.list.search();
          } else {
            this.$message.error(res.msg);
          }
        });
    },

    toPoint(row) {
      this.$router.push({
        name: "activityPointForm",
        query: {
          recordId: row.recordId, // 记录id
          scheduleId: row.scheduleId, // 场次id
          activityId: this.$route.query.id, // 活动id
          applicantsId: row.applicantsId, // 参与人id
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.addActivity {
  box-sizing: border-box;
  height: 100%;
  .create-dialog {
    ::v-deep > .el-dialog {
      width: 430px;
    }
    .wrap {
      display: flex;
      .btn {
        margin-left: 20px;
      }
    }
    .info-card {
      border-radius: 8px;
      padding: 12px;
      font-size: 14px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 10%);
      > p {
        margin-bottom: 10px;
        > span {
          display: inline-block;
          width: 80px;
          text-align: left;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
